<template>
  <div class="px-3 py-2">
    <search-widget compact />

    <collection-picker compact />

    <!-- <hr /> -->

    <br />

    <category-picker compact />

    <br />

    <favorite-picker compact />

    <br /><br />
  </div>
</template>

<script>
import CategoryPicker from "./Home/CategoryPicker.vue";
import CollectionPicker from "./Home/CollectionPicker.vue";
import FavoritePicker from "./Home/FavoritePicker.vue";
import SearchWidget from "./Home/SearchWidget.vue";

export default {
  components: {
    CategoryPicker,
    CollectionPicker,
    FavoritePicker,
    SearchWidget
  },
  name: "AppSidebar"
};
</script>

<style scoped lang="scss">
.sep {
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
</style>